<template>
  <div>
    <v-container>
      <v-row @click="selectOptionBrands()">
        <v-col>
          <v-card color="#69B8B3" dark class="pa-4 pl-0">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="subtitle-1"> View Ratings Of All Brands </v-card-title>
              </div>

              <v-avatar class="ma-3" size="30" tile>
                <v-icon >mdi-star</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row @click="selectOptionSearch()">
        <v-col cols="12">
          <v-card color="#037171" dark class="pa-4 pl-0">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="subtitle-1"> Search for A Brand </v-card-title>
              </div>

              <v-avatar class="ma-3" size="30" tile>
                <v-icon>mdi-yeast</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {
    selectOptionBrands() {
      this.$router.push({
        name: "ireviewCategory",
        params: { redirect: "selectOptionBrands" },
      });
    },

    selectOptionSearch() {
      this.$router.push({
        name: "ireviewSearch",
        params: { redirect: "selectOptionSearch" },
      });
    },
  },
};
</script>